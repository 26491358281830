import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { HomePageBlogEntry } from './HomePageBlogEntry';

import '../css/tailwind.css';

type HomePageBlogEntryCardsProps = {
  blogEntries: Array<HomePageBlogEntry>;
};

const classNames = {
  container: 'flex flex-wrap items-stretch',
  cardArticle: [
    'flex flex-col flex-1',
    'min-w-32 max-w-lg',
    'rounded overflow-hidden',
    'shadow-md',
    'bg-white',
    'mx-4 my-4',
  ].join(' '),
  minorLink: [
    'inline-block text-sm',
    'px-3 mr-2',
    'text-sm font-semibold',
    'rounded',
    'no-underline',
    'text-gray-700 hover:text-black',
    'bg-gray-200 hover:bg-gray-300',
  ].join(' '),
};

const HomePageBlogEntryCards: React.FunctionComponent<HomePageBlogEntryCardsProps> = (props) => {
  const cards = props.blogEntries.map((blogEntry) => {
    const featureImage = blogEntry.featuredImage && (
      <Link to={blogEntry.slugUrl} title={blogEntry.title}>
        <Img fluid={blogEntry.featuredImage} />
      </Link>
    );
    return (
      <article key={blogEntry.slugUrl} className={classNames.cardArticle} style={{ minWidth: '16em' }}>

        {featureImage}

        <div className="px-6 py-4 flex-1">
          <Link to={blogEntry.slugUrl}
                className="font-bold text-xl block leading-none">
            {blogEntry.title}
          </Link>

          <time dateTime={blogEntry.publishedDateIso8601}
                className="block leading-none mt-2 mb-4 text-xs text-gray-700">
            {blogEntry.publishedDateDisplay}
          </time>

          <p className="text-gray-800 text-base leading-tight">
            {blogEntry.excerpt}
          </p>
        </div>

        <div className="px-6 py-4">
          <Link to={blogEntry.slugUrl} className={classNames.minorLink}>Read more</Link>
        </div>

      </article>
    );
  });

  return (
    <div className={classNames.container}>
      {cards}
    </div>
  );
};

export default HomePageBlogEntryCards;
