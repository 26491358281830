import React from 'react';
import { HomePageBlogEntry } from './HomePageBlogEntry';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Seo from '../components/Seo';
import HomePageBlogEntryCards from './HomePageBlogEntryCards';

import '../css/tailwind.css';
import StaticMdxContent from '../components/StaticMdxContent';

type HomePageTemplateProps = {
  blogEntries: Array<HomePageBlogEntry>;
}

const HomePageTemplate: React.FunctionComponent<HomePageTemplateProps> = (props) => {
  const { blogEntries } = props;

  return (
    <div className="min-h-screen bg-gray-100">
      <Seo
        title="Just Juzzy Blog"
        description="The home page of Justin Lovell's thoughts with a view to improve the software world through architectural design"
      />

      <Header/>

      <main className="container mx-auto">

        <section className="my-8 mx-4 max-w-5xl">
          <div className="font-lg font-medium">
            <StaticMdxContent slug="/home-page-intro/"/>
          </div>

          <div className="flex flex-col md:flex-row text-sm font-medium">
            <section className="md:mr-16 flex-initial">
              <StaticMdxContent slug="/home-page-current-profile/"/>
            </section>
            <section className="flex-initial">
              <StaticMdxContent slug="/home-page-tech-experience/"/>
            </section>
          </div>
        </section>

        <section className="py-12">
          <h1 className="font-extrabold text-2xl mx-4">Recent Blog Entries</h1>
          <HomePageBlogEntryCards blogEntries={blogEntries.slice(0, 8)}/>
        </section>

      </main>

      <Footer/>
    </div>
  );
};

export default HomePageTemplate;
