import React from 'react';
import { FluidObject } from 'gatsby-image';
import { graphql } from 'gatsby';
import { HomePageBlogEntry } from '../home/HomePageBlogEntry';
import HomePageTemplate from '../home/HomePageTemplate';


type HomePageGatsbyComponentProps = {
  data: {
    allMdx: {
      edges: Array<{
        node: {
          excerpt: string;

          fields: {
            slug: string;
          };

          frontmatter: {
            title: string;
            publishedDateIso8601: string;
            publishedDateDisplay: string;
            description: string;

            featuredImageSharp?: {
              childImageSharp? : {
                fluid?: FluidObject;
              };
            };
          };
        };
      }>;
    };
  };
};

// todo: 404 page
// todo: Copyright
// todo: Social media

// https://codepen.io/ig_design/pen/yrwgwO Scroll to op
// https://codepen.io/ig_design/pen/VgaXaY overlay menu
// https://codepen.io/aaroniker/pen/GLyzaG menu butoon

const HomePageGatsbyComponent:
  React.FunctionComponent<HomePageGatsbyComponentProps> = (props) => {
    const blogEntries = props.data.allMdx.edges.map(({ node }) => {
      const { frontmatter, fields } = node;
      const entry: HomePageBlogEntry = {
        title: frontmatter.title,
        slugUrl: fields.slug,
        publishedDateIso8601: frontmatter.publishedDateIso8601,
        publishedDateDisplay: frontmatter.publishedDateDisplay,
        excerpt: frontmatter.description || node.excerpt,
        featuredImage: frontmatter.featuredImageSharp?.childImageSharp?.fluid,
      };

      return entry;
    });

    return <HomePageTemplate blogEntries={blogEntries}/>;
  };

export default HomePageGatsbyComponent;


export const pageQuery = graphql`
  query {
    allMdx: allMdx(
      limit: 20,
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { fields: {collection: {eq: "blog"}} }
    ) {
      edges {
        node {
          excerpt

          fields {
            slug
          }

          frontmatter {
            title

            featuredImageSharp: featuredImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            publishedDateDisplay: date(formatString: "DD MMMM YYYY")
            publishedDateIso8601: date
            description
          }
        }
      }
    }
  }
`;
