import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { DesignSystemMdxComponents } from '../layout/DesignSystem';

type QueryResultShape = {
  allMdx: {
    edges: Array<{
      node: {
        fields: {
          slug: string;
        };
        body: string;
      };
    }>;
  };
}

type StaticMdxContentProps = {
  slug: string;
}

const StaticMdxContent: React.FunctionComponent<StaticMdxContentProps> = (props) => {
  const data: QueryResultShape = useStaticQuery(graphql`
    {
      allMdx(filter: {fields: {collection: {eq: "static"}}}) {
        edges {
          node {
            fields {
              slug
            }
            body
          }
        }
      }
    }
  `);

  const mdx = data.allMdx.edges.find((edge) => edge.node.fields.slug === props.slug)
    || { node: { body: 'NOT FOUND' } };

  return (
    <MDXProvider components={DesignSystemMdxComponents}>
      <MDXRenderer>{mdx.node.body}</MDXRenderer>
    </MDXProvider>
  );
};

export default StaticMdxContent;
